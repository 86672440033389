import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle} from '@fortawesome/free-solid-svg-icons';

const THEME = {
  DARK: 'dark',
  LIGHT: 'light'
};

function ThemeSelect() {
  const [systemThemeDark, setSystemThemeDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);

  // Sets the theme, local storage if it exists , otherwise system theme
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) return savedTheme;
    
    return systemThemeDark
      ? THEME.DARK 
      : THEME.LIGHT;
  });

  // Memoized theme oppositeTheme calculation
  const oppositeTheme = useCallback((currentTheme) => {
    return currentTheme === THEME.DARK ? THEME.LIGHT : THEME.DARK;
  }, []);

  // Handle theme changes link click
  const handleThemeChange = useCallback((event) => {
    event.preventDefault();
    setTheme(oppositeTheme(theme));
  }, [theme, oppositeTheme]);

  // Effect to update DOM and localStorage
  useEffect(() => {
    try {
      // Update HTML data attribute
      document.documentElement.setAttribute('data-theme', theme);
      
      // Update theme link text and aria-label
      const themeLink = document.querySelector('#theme-changer + a');
      if (themeLink) {
        themeLink.title = `Change to ${oppositeTheme(theme)} mode`;
        themeLink.setAttribute(
          'aria-label',
          `Change to ${oppositeTheme(theme)} mode`
        );
      }

      // Update localStorage
      if ((systemThemeDark && theme === THEME.LIGHT) || (!systemThemeDark && theme === THEME.DARK)) {
        // set local storage if theme not the same as system theme
        localStorage.setItem('theme', theme);
      } else {
        // remove local storage if theme is the same as system theme
        localStorage.removeItem('theme');
      }
    } catch (error) {
      console.error('Error updating theme:', error);
    }
  }, [theme, systemThemeDark, oppositeTheme]);

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleSystemThemeChange = (e) => {
      if (!localStorage.getItem('theme')) {
        // If no theme is stored, update theme to match system theme
        setSystemThemeDark(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleSystemThemeChange);
    
    return () => {
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, []);

  return (
    <div id="theme-changer">
      <Link to="#" onClick={handleThemeChange} title={`Switch to ${oppositeTheme(theme)} mode`} aria-label={`Switch to ${oppositeTheme(theme)} mode`}>
        <FontAwesomeIcon id="theme-select" icon={faCircle} />
      </Link>
    </div>
  );
}

export default ThemeSelect;
